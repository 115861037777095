import './App.css';
import discord from './images/discord.png';
import opensea from './images/opensea.png';
import twitter from './images/twitter.png';
import etherscan from './images/etherscan.png';
import logo from './images/logo.png';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from "./Home";
import Error from "./Error";


function App() {
  return (
    <Router>
    <div className="App">      
      <header className="App-header">
          <a href="https://www.kingdom-swap.com" alt ="Logo" target="_blank" rel="noreferrer"> <img src={logo} alt="Twitter" title="Twitter" height="100" widht="100"></img></a>          
      </header>

      <main>
          <div className="content">
          <Routes>
             <Route path="/" element={<Home />} />;                          
             <Route path="*" element={<Error />} />;       
          </Routes>          
          </div> 
      </main> 

      <footer>          
          <div className="Social">
          <a href="https://discord.gg/kingdomswap" target="_blank" rel="noreferrer"> <img src={discord}  alt="Discord" title="Discord" height="40" widht="40"></img></a> 
          <a href="https://www.opensea.io" target="_blank" rel="noreferrer"><img src={opensea} alt="Open Sea" title="Open Sea: Coming soon." height="40" widht="40"></img></a>
          <a href="https://twitter.com/kingdomswapgame" target="_blank" rel="noreferrer"><img src={twitter} alt="Twitter" title="Twitter" height="40" widht="40"></img></a>          
          <a href="https://polygonscan.com/" target="_blank" rel="noreferrer"><img src={etherscan} alt="Etherscan" title="Etherscan: Coming soon." height="40" widht="40"></img></a>
          </div>          

          <div className="Terms">
          <a href="https://docs.kingdom-swap.com/" alt="KS Dics" target="_blank" rel="noreferrer">Kingdom Swap Docs</a>
          </div>
      </footer>

    </div>
    </Router>
  );
}

export default App;